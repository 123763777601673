import useDictStore from '@/store/modules/dict'
import type { Router } from 'vue-router'
import { isLogin } from '@/utils/auth'

export default function setupDictGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const dictStore = useDictStore()
    if (dictStore.getDictList.length === 0 && isLogin()) {
      await dictStore.getDict()
    }
    next()
  })
}
