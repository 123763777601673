import axios from 'axios'

export function customerList(params: any) {
  return axios.get('/api/customer/list', { params })
}

export function customerCreate(params: any) {
  return axios.post('/api/customer/create', params)
}

export function customerMteEdit(params: any) {
  return axios.patch('/api/customer/mte/edit', params)
}

export function customerFollowCreate(params: any) {
  return axios.post('/api/customer/follow/create', params)
}

export function customerFollowList(params: any) {
  return axios.get('/api/customer/follow/list', { params })
}

export function customeBbookVisitCreate(params: any) {
  return axios.post('/api/customer/bookVisit/create', params)
}

export function customeBookVisitList(params: any) {
  return axios.get('/api/customer/bookVisit/list', { params })
}

export function customeTel(params: any) {
  return axios.get('/api/customer/tel', { params })
}

export function customeVisitCreate(params: any) {
  return axios.post('/api/customer/visit/create', params)
}

export function customeVisitList(params: any) {
  return axios.get('/api/customer/visit/list', { params })
}

export function customeVisitApply(params: any) {
  return axios.patch('/api/customer/visit/apply', params)
}

export function customeDealCreate(params: any) {
  return axios.post('/api/customer/deal/create', params)
}

export function customeDealList(params: any) {
  return axios.get('/api/customer/deal/list', { params })
}

export function customeDealApply(params: any) {
  return axios.patch('/api/customer/deal/apply', params)
}

export function customeAllocateManuallyInternal(params: any) {
  return axios.post('/api/customer/trans/manual/internal', params)
}

export function customeTransManualSea(params: any) {
  return axios.post('/api/customer/trans/manual/sea', params)
}

export function customeQualEdit(params: any) {
  return axios.patch('/api/customer/qual/edit', params)
}

export function customeTransRecordNew(params: any) {
  return axios.get('/api/customer/trans/record/new', { params })
}

export function customeTransRecordInternal(params: any) {
  return axios.get('/api/customer/trans/record/internal', { params })
}

export function customeTransRecordSea(params: any) {
  return axios.get('/api/customer/trans/record/sea', { params })
}

export function customeTransList(params: any) {
  return axios.get('/api/customer/trans/list', { params })
}

export function customerTransGet(params: any) {
  return axios.post('/api/customer/trans/get', params)
}

export function customeTransTel(params: any) {
  return axios.get('/api/customer/trans/tel', { params })
}

export function customeNoticeOne(params: any) {
  return axios.get('/api/customer/notice/one', { params })
}

export function customeNoticeEdit(params: any) {
  return axios.patch('/api/customer/notice/edit', params)
}

export function customeImportSeaCreate(params: any) {
  return axios.post('/api/customer/import/sea/create', params)
}

export function customeImportSeaEdit(params: any) {
  return axios.patch('/api/customer/import/sea/edit', params)
}

export function customerTransManualAllocate(params: any) {
  return axios.post('/api/customer/trans/manual/allocate', params)
}

export function customeImportSeaList(params: any) {
  return axios.get('/api/customer/import/sea/list', { params })
}

export function customerDailyTask(params: any) {
  return axios.get('/api/customer/dailyTask', { params })
}

export function customerCall(params: { cid: number }) {
  return axios.post('/api/customer/call', params)
}

export function customerManualDailyTask(params: { uid: number }) {
  return axios.post('/api/customer/trans/manual/dailyTask', params)
}

export function customerTransRecordInternalCache(params: any) {
  return axios.get('/api/customer/trans/record/internalCache', params)
}

export function customerTransRecordSeaCache(params: any) {
  return axios.get('/api/customer/trans/record/seaCache', params)
}

export function customerSearchFirst(params: { tels: string[] }) {
  return axios.post('/api/customer/search/first', params)
}
