import { defineStore } from 'pinia'
import { login as userLogin, logout as userLogout } from '@/api/user'
import { setToken, clearToken } from '@/utils/auth'
import { removeRouteListener } from '@/utils/route-listener'
import * as jwtDecodeModule from 'jwt-decode'
import useAppStore from '../app'
import useDictStore from '../dict'

const useUserStore = defineStore('user', {
  state: (): any => ({
    role_id: '',
    username: '',
    telephone: '',
    id: '',
    user_level: '',
    company_id: '',
    department_id: '',
    team_id: ''
  }),

  getters: {
    userInfo(state: any): any {
      return { ...state }
    },

    role(state: any) {
      return state.role_id
    },

    isAdmin(state: any) {
      return state.role_id === 1
    },

    isYunYing(state: any) {
      return state.role_id === 5
    },

    isCAndD(state: any) {
      return state.user_level === 4 || state.user_level === 3
    },

    isSaleman(state: any) {
      return state.role_id === 4 || state.role_id === 3 || state.role_id === 2
    },

    getRole(state: any) {
      switch (state.role_id) {
        case 1:
          return 'admin'
        case 2:
          return 'deptManager'
        case 3:
          return 'teamLeader'
        case 4:
          return 'teamMember'
        default:
          return 'unknown'
      }
    },

    waterMarkStr(state: any) {
      return `${state.username} ${state.telephone}`
    }
  },

  actions: {
    // Set user's information
    setInfo(partial: Partial<any>) {
      this.$patch(partial)
    },

    // Reset user's information
    resetInfo() {
      this.$reset()
    },

    // Get user's information
    async info() {
      const jwt = jwtDecodeModule.jwtDecode
      const tokenInfo: any = jwt(localStorage.getItem('token') as string)
      this.setInfo(tokenInfo.UserInfo)
    },

    // Login
    async login(loginForm: any) {
      try {
        const res = await userLogin(loginForm)
        setToken(res.data.token)
        await this.info()
        await useDictStore().getDict()
      } catch (err) {
        clearToken()
        throw err
      }
    },
    logoutCallBack() {
      const appStore = useAppStore()
      this.resetInfo()
      clearToken()
      removeRouteListener()
      appStore.clearServerMenu()
    },
    // Logout
    async logout() {
      try {
        await userLogout()
      } finally {
        this.logoutCallBack()
      }
    }
  }
})

export default useUserStore
