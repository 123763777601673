import { defineStore } from 'pinia'
import { customeNoticeOne, customeNoticeEdit } from '@/api/customer-management'
import { Notification, Button } from '@arco-design/web-vue'
import useUserStore from '@/store/modules/user'

const confirm = (id: string) => {
  customeNoticeEdit({ Id: id, Status: 2 })
}

const useMsgStore = defineStore('msg', {
  persist: {
    storage: window.sessionStorage
  },
  state: () => ({
    msg: [] as any,
    timer: null as any
  }),
  getters: {
    haveMsg(state) {
      return state.msg.length > 0
    },
    isGetting(state) {
      return state.timer !== null
    }
  },
  actions: {
    sendMsg(msg: any) {
      Notification.success({
        title: '有新顾客啦！',
        content: `客户ID：${msg.customer_id}`,
        duration: 0,
        closable: true,
        closeIconElement: (
          <Button type="primary" status="warning" size="mini" onClick={() => confirm(msg.id)}>
            确认
          </Button>
        )
      })
    },
    getMsg() {
      customeNoticeOne({
        UserId: useUserStore().userInfo.id
      })
        .then((res) => {
          if (res.data.One) {
            const one = res.data.One
            const isExist = this.msg.find((item: any) => {
              return item.id === one.id
            })
            if (!isExist) {
              this.msg.push(one)
              this.sendMsg(one)
            } else {
              console.log('已存在')
            }
          }

          this.timer = setTimeout(() => {
            this.getMsg()
          }, 10 * 1000)
        })
        .catch((error) => {
          console.error(error)
          this.clear()
        })
    },
    startGetMsg() {
      this.timer = setTimeout(() => {
        this.getMsg()
      }, 10 * 1000)
    },
    clear() {
      clearTimeout(this.timer as number)
      this.$reset()
    }
  }
})

export default useMsgStore
