<script lang="ts" setup>
  import { reactive, onMounted, defineModel } from 'vue'
  import { brandList, brandProductList } from '@/api/branding'

  const state = reactive({
    brandArr: [] as any[]
  })

  const productId = defineModel({
    type: Array,
    default: null
  })

  const loadMore = (option, done) => {
    brandProductList({ brandId: option.id }).then((res) => {
      const rel = res.data.List.map((item) => {
        return {
          id: item.id,
          name: item.name,
          isLeaf: true
        }
      })
      done(rel)
    })
  }

  onMounted(() => {
    brandList({ switch: 1 }).then((res) => {
      state.brandArr = res.data.List
    })
  })
</script>

<template>
  <div>
    <a-cascader
      v-model:model-value="productId"
      multiple
      allow-clear
      :max-tag-count="1"
      :options="state.brandArr"
      :field-names="{ label: 'name', value: 'id' }"
      :load-more="loadMore"
      :format-label="
        (options) => {
          return options[1].name
        }
      "
    />
  </div>
</template>

<style lang="less" scoped></style>
