import { defineStore } from 'pinia'
import { rbacPremList } from '@/api/data-management'
import { useUserStore } from '@/store'
import { Message } from '@arco-design/web-vue'

const useRouterStore = defineStore('router', {
  persist: {
    storage: window.sessionStorage
  },
  state: () => ({
    router: [] as any,
    rbacTree: [] as any
  }),
  getters: {
    routers(state) {
      return state.router
    },
    rbacTreeData(state) {
      return state.rbacTree
    }
  },
  actions: {
    async getRouter() {
      const layouModules: any = import.meta.glob('../../../layout/*.{vue,tsx}')
      const viewsModules: any = import.meta.glob('../../../views/**/*.{vue,tsx}')
      const dynamicViewsModules: any = { ...layouModules, ...viewsModules }
      let rbacPrem = [] as any

      function dynamicImport(Modules: any, component: string) {
        const keys = Object.keys(Modules)
        const matchKeys = keys.filter((key) => {
          const k = key.replace(/..\/views|../, '')
          return k.includes(`${component}`)
        })

        if (matchKeys?.length === 1) {
          const matchKey = matchKeys[0]
          return Modules[matchKey]
        }
        if (matchKeys?.length > 1) {
          return false
        }
        return false
      }

      function listToTree(list: any[]) {
        const menu = list.filter((item: any) => item.parent_id === 0)

        function toTree(node: any) {
          const children = list.filter((item: any) => item.parent_id === node.id)
          node.children = children

          if (children.length > 0) {
            children.map((item: any) => toTree(item))
          }
          return children
        }

        return menu.map((item: any) => {
          toTree(item)

          return item
        })
      }

      async function getRouterByRole() {
        const params = { roleId: useUserStore().role }

        const res = await rbacPremList(params)

        if (!res.data.list.length) {
          Message.error('当前角色没有权限, 请联系管理员添加权限')
        }

        const tree = listToTree(res.data.list)

        rbacPrem = tree

        function formatRouter(Tree: any[], parent: any, deep = 0) {
          const Router = Tree

          if (!Router.length) return []

          return Router.forEach((item) => {
            const { path, name, icon, sort, code } = item

            item.permType = item.perm_type
            if (item.perm_type === 2) {
              parent.meta.auth.push({
                name: item.name,
                code: item.code
              })
            } else {
              const router = {
                path,
                name: code,
                component:
                  deep === 0
                    ? () => dynamicImport(dynamicViewsModules, 'default-layout')()
                    : () => dynamicImport(dynamicViewsModules, `${path}/index.vue`)(),

                meta: {
                  locale: name,
                  icon: icon || 'icon-menu',
                  order: sort,
                  auth: []
                },
                children: [] as any[]
              }
              if (deep === 0) {
                parent.push(router)
                parent.sort((a: any, b: any) => a.meta.order - b.meta.order)
              } else {
                parent.children.push(router)
                parent.children.sort((a: any, b: any) => a.meta.order - b.meta.order)
              }

              if (item.children && item.children.length > 0) {
                formatRouter(item.children, router, deep + 1)
              }
            }
          })
        }
        const router = []
        formatRouter(tree, router, 0)

        return [...router]
      }
      this.router = await getRouterByRole()
      this.rbacTree = rbacPrem
    },

    clear() {
      this.$reset()
    }
  }
})

export default useRouterStore
