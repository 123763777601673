import { defineStore } from 'pinia'
import { dictTree } from '@/api/data-management'

type Item = {
  IKey: string
  IValue: string
  id: number
  description: string
  createAt: string
  updateAt: string | undefined
}

const useDictStore = defineStore('dict', {
  persist: {
    storage: window.sessionStorage
  },
  state: () => ({
    dicts: []
  }),
  getters: {
    getDictList(state) {
      return state.dicts
    },
    getDictByCode(state) {
      return (code: string): { items: Item[] | null } => {
        let dict = state.dicts.find((item: any) => item.code === code) as any
        if (!dict) {
          dict = { items: [] }
          Promise.reject(new Error(`${code} 字典不存在`))
        }
        return dict
      }
    }
  },
  actions: {
    async getDict() {
      await dictTree().then((res) => {
        this.dicts = res.data.dicts
      })
    },
    clearDcit() {
      this.$reset()
    }
  }
})

export default useDictStore
