import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'
import { Message } from '@arco-design/web-vue'
import { getToken } from '@/utils/auth'
import { useUserStore } from '@/store'
import useUser from '@/hooks/user'

export interface HttpResponse<T = unknown> {
  status: number
  message: string
  code: number
  data: T
}

if (import.meta.env.VITE_API_BASE_URL) {
  axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
}

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // let each request carry token
    // this example using the JWT token
    // Authorization is a custom headers key
    // please modify it according to the actual situation
    const token = getToken()
    if (token) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers.Authorization = `${token}`
    }
    return config
  },
  (error) => {
    // do something
    return Promise.reject(error)
  }
)
// add response interceptors
axios.interceptors.response.use(
  (response) => {
    const res = response.data as any

    if (res.code && res.code !== 200) {
      Message.error({
        content: res.message || 'Request Error',
        duration: 2 * 1000
      })
      return Promise.reject(res)
    }

    return res
  },
  (error) => {
    let msg = error.message || 'Request Error'
    if (error.response?.status === 403) {
      msg = '登录已过期，请重新登录'
      // 清理所有登录数据
      useUserStore().logout()
      useUser().logout()
    }

    Message.error({
      content: msg,
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
