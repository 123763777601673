<script lang="ts" setup>
  import { defineModel } from 'vue'
  import useDictStore from '@/store/modules/dict'

  const model = defineModel({ type: Number, default: null })
  const props = defineProps({
    code: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '200'
    }
  })
  const dict = useDictStore().getDictByCode(props.code).items as any
</script>

<template>
  <a-select v-model:model-value="model" :style="{ width: `${props.width}px` }">
    <a-option v-for="item in dict" :key="item.IKey" :value="parseInt(item.IKey)">{{ item.IValue }}</a-option>
  </a-select>
</template>

<style lang="less" scoped></style>
