import { useUserStore } from '@/store'
import { nextTick } from 'vue'
import useRouterStore from '@/store/modules/router'
import importRouter from '@/router'
import useDictStore from '@/store/modules/dict'
import { Router } from 'vue-router'

function resetRouter(router: Router) {
  const resetWhiteNameList = ['login', 'root', 'redirectWrapper', 'notFound', 'redirect', 'downloadBrowser']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export default function useUser(router = importRouter) {
  const userStore = useUserStore()
  const logout = async (logoutTo?: string) => {
    await userStore.logout()
    sessionStorage.clear()
    resetRouter(router)
    useRouterStore().clear()
    useDictStore().clearDcit()
    const currentRoute = router.currentRoute.value
    return new Promise((resolve) => {
      nextTick(() => {
        router.push({
          name: logoutTo && typeof logoutTo === 'string' ? logoutTo : 'login',
          query: {
            ...router.currentRoute.value.query,
            redirect: currentRoute.name as string
          }
        })
        resolve(true)
      })
    })
  }

  const loginInit = async () => {
    // 初始化路由
    await useRouterStore().getRouter()
    useRouterStore().routers.forEach((item: any) => {
      router.addRoute(item)
    })
  }
  return {
    logout,
    loginInit
  }
}
