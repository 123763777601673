import axios from 'axios'

export function brandList(params: any) {
  return axios.get('/api/brand/list', { params })
}

export function brandCreate(params: any) {
  return axios.post('/api/brand/create', params)
}

export function brandEdit(params: any) {
  return axios.patch('/api/brand/edit', params)
}

export function brandDelete(params: any) {
  return axios.delete('/api/brand/delete', { params })
}

export function brandProductList(params: any) {
  return axios.get('/api/brand/product/list', { params })
}

export function brandProductCreate(params: any) {
  return axios.post('/api/brand/product/create', params)
}

export function brandProductEdit(params: any) {
  return axios.patch('/api/brand/product/edit', params)
}

export function brandProductDelete(params: any) {
  return axios.delete('/api/brand/product/delete', { params })
}

export function brandProductBindUser(params: any) {
  return axios.post('/api/brand/product/bindUser', params)
}

export function brandProductBindUserList(params: any) {
  return axios.get('/api/brand/product/bindUserList', { params })
}

export function brandProductBindUserEdit(params: any) {
  return axios.post('/api/brand/product/bindUserEdit', params)
}
