export default function isChrome() {
  // 本地开发环境直接返回false
  if (import.meta.env.MODE === 'development') return true

  const browserMimeTypes = navigator.mimeTypes
  // edge
  // if (navigator.userAgent.toLocaleLowerCase().includes('edg')) return true
  // 360浏览器的mimeTypes对象只有两个属性
  if (browserMimeTypes.length > 2) return false
  // 微信浏览器
  if (navigator.userAgent.toLocaleLowerCase().includes('messenger')) {
    return false
  }
  return true
}
