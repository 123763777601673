<script lang="tsx" setup>
  import { ref, watch } from 'vue'
  import { useUserStore } from '@/store'
  import { sysNoticeQuery, sysNoticeConfirm } from '@/api/data-management'

  const show = ref(false)
  const noticeStr = ref('')
  const userStore = useUserStore()
  let nowNoticeId: number
  let timer: any

  const getNotice = async () => {
    const {
      data: { Msg }
    } = await sysNoticeQuery({})

    if (Msg) {
      noticeStr.value = Msg.msg
      nowNoticeId = Msg.id
      show.value = true
    } else {
      timer = setTimeout(() => {
        getNotice()
      }, 30 * 1000)
    }
  }

  const handleOk = async () => {
    await sysNoticeConfirm({ id: nowNoticeId })
    getNotice()
    show.value = false
  }

  watch(
    () => userStore.role,
    (val) => {
      if (val) {
        getNotice()
      } else {
        clearTimeout(timer)
      }
    },
    {
      immediate: true
    }
  )
</script>

<template>
  <a-modal
    width="600px"
    title="系统提示"
    :mask-closable="false"
    :closable="false"
    :esc-to-close="false"
    :hide-cancel="true"
    :visible="show"
    @ok="handleOk"
  >
    <div>
      <p style="white-space: pre-wrap">{{ noticeStr }}</p>
    </div>
  </a-modal>
</template>
