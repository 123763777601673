<template>
  <a-config-provider :locale="locale" update-at-scroll>
    <router-view />
    <global-setting />
    <Notice />
  </a-config-provider>
</template>

<script lang="ts" setup>
  import { computed, watch } from 'vue'
  import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
  import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn'
  import GlobalSetting from '@/components/global-setting/index.vue'
  import useLocale from '@/hooks/locale'
  import { useUserStore } from './store'
  import useMsgStore from './store/modules/msg'
  import Notice from './components/notice/index.vue'

  const { currentLocale } = useLocale()
  const locale = computed(() => {
    switch (currentLocale.value) {
      case 'zh-CN':
        return zhCN
      case 'en-US':
        return enUS
      default:
        return enUS
    }
  })

  const userStore = useUserStore()

  watch(
    () => userStore.role,
    (val) => {
      const msgStore = useMsgStore()
      if (val === 4 || val === 3) {
        if (msgStore.isGetting) {
          msgStore.clear()
        }
        msgStore.startGetMsg()
      } else {
        msgStore.clear()
      }
    },
    {
      immediate: true
    }
  )
</script>
