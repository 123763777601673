import { DirectiveBinding } from 'vue'
import router from '@/router'

function checkPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { arg, value } = binding
  const authArr = router.currentRoute.value.meta.auth as any[]

  if (authArr.findIndex((item) => item.code === arg) === -1) {
    if (value === 'pointEvent') {
      el.style.pointerEvents = 'none'
    } else {
      el.remove()
    }
  }
}

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding)
  }
}
