import type { Router, LocationQueryRaw } from 'vue-router'
import NProgress from 'nprogress' // progress bar
import { useUserStore } from '@/store'
import { isLogin } from '@/utils/auth'
import useRouterStore from '@/store/modules/router'

export default function setupUserLoginInfoGuard(router: Router) {
  const getInfo = async (to: any, from: any, next: any) => {
    const userStore = useUserStore()
    try {
      await userStore.info()
    } catch (error) {
      await userStore.logout()
      next({
        name: 'login',
        query: {
          redirect: to.name,
          ...to.query
        } as LocationQueryRaw
      })
    }
  }

  router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const userStore = useUserStore()
    if (isLogin()) {
      if (!userStore.role) {
        await getInfo(to, from, next)
      }
      if (userStore.role && router.getRoutes().length <= 6) {
        await useRouterStore().getRouter()
        const serverRouters = useRouterStore().routers
        serverRouters.forEach((item) => {
          router.addRoute(item)
        })

        next(to.fullPath)
        return
      }

      if (to.name === 'login') {
        next({ name: 'home-page' })
        return
      }
      next()
    } else {
      if (to.name === 'login' || to.name === 'downloadBrowser') {
        next()
        return
      }
      next({
        name: 'login',
        query: {
          redirect: to.name === 'notFound' ? '' : to.name,
          ...to.query
        } as LocationQueryRaw
      })
      NProgress.done()
    }
  })
}
