import type { Router } from 'vue-router'
import isChrome from '@/utils/getBrowser'

export default function setupDictGuard(router: Router) {
  router.beforeEach((to, from, next) => {
    if (!isChrome() && to.name !== 'downloadBrowser') {
      next({ name: 'downloadBrowser' })
    } else {
      if (isChrome() && to.name === 'downloadBrowser') {
        next('/')
      }
      next()
    }
  })
}
