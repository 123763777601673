import axios from 'axios'

export function getCompanyTreeData() {
  return axios.get('/api/org/tree')
}

export function getCompanyList() {
  return axios.get('/api/org/list')
}

export function orgEdit(data: object) {
  return axios.patch('/api/org/edit', data)
}

export function orgCreate(data: object) {
  return axios.post('/api/org/create', data)
}

export function orgDelete(data: object) {
  return axios.delete('/api/org/delete', { data })
}

export function userList(params: object) {
  return axios.get('/api/user/list', { params })
}

export function userCreate(params: object) {
  return axios.post('/api/user/create', params)
}

export function userEdit(params: object) {
  return axios.patch('/api/user/edit', params)
}

export function userEditPassword(params: object) {
  return axios.patch('/api/user/password', params)
}

export function userBindProduct(params: object) {
  return axios.post('/api/user/bindProduct', params)
}

export function userDepart(params: object) {
  return axios.delete('/api/user/depart', { params })
}

export function userEnable(params: object) {
  return axios.post('/api/user/enable', params)
}

export function entCompanyList(params: { Id?: number; Name?: string }) {
  return axios.get('/api/ent/company/list', { params })
}

export function entCompanyCreate(params: { name: string }) {
  return axios.post('/api/ent/company/create', params)
}

export function entCompanyEdit(params: { id: string; name: string }) {
  return axios.patch('/api/ent/company/edit', params)
}

export function entDeptCreate(params: { companyId: number; name: string }) {
  return axios.post('/api/ent/department/create', params)
}

export function entDeptList(params: { Id?: number; Name?: string; CompanyId?: number }) {
  return axios.get('/api/ent/department/list', { params })
}

export function entDeptEdit(params: { id: string; name?: string; companyId?: number }) {
  return axios.patch('/api/ent/department/edit', params)
}

export function entTeamList(params: { Id?: number; Name?: string; CompanyId?: number; departmentId?: number }) {
  return axios.get('/api/ent/team/list', { params })
}

export function entTeamCreate(params: { company_id: number; name: string; department_id: number }) {
  return axios.post('/api/ent/team/create', params)
}

export function entTeamEdit(params: { id: string; name?: string; companyId: number; departmentId: number }) {
  return axios.patch('/api/ent/team/edit', params)
}

export function entTeamDel(params: { id: string }) {
  return axios.delete('/api/ent/team/del', { params })
}

export function entDeptDel(params: { id: string }) {
  return axios.delete('/api/ent/department/del', { params })
}

export function entCompanyDel(params: { id: string }) {
  return axios.delete('/api/ent/company/del', { params })
}
