<script lang="ts" setup>
  import { reactive, defineOptions, defineModel, onMounted } from 'vue'
  import { userList } from '@/api/organizational-structure'
  import { useUserStore } from '@/store'
  import CompanyTree from './component/CompanyTree.vue'
  import UserTable from './component/UserTable.vue'

  defineOptions({
    name: ''
  })
  const state = reactive({
    selectedUser: [] as any,
    filterStr: '',
    userList: [] as any[],
    filterUserList: [] as any,
    filterLevel: [] as any
  })
  const selectedUserKeys = defineModel({ required: true, type: Array })

  const props = defineProps({
    width: {
      type: String,
      default: '100%'
    },
    allUser: {
      type: Boolean,
      default: false
    }
  })

  const reset = () => {
    selectedUserKeys.value = []
    state.selectedUser = []
    state.filterStr = ''
    state.filterLevel = []
    state.filterUserList = state.userList
  }

  defineExpose({
    reset
  })

  const treeClick = (data: any) => {
    const { node } = data
    const params = {
      companyId: undefined,
      departmentId: undefined,
      teamId: undefined,
      accountStatus: props.allUser ? undefined : 1
    }
    if (node.type === 'company') {
      params.companyId = node.id
    } else if (node.type === 'dept') {
      params.departmentId = node.id
    } else if (node.type === 'team') {
      params.teamId = node.id
    } else if (!node.type) {
      const userInfos = useUserStore().userInfo
      params.companyId = userInfos.company_id
      params.departmentId = userInfos.department_id
      params.teamId = userInfos.team_id
    }
    userList(params).then((res) => {
      const list =
        res.data.list?.filter(
          // 筛选掉管理员和运营
          (item: any) => !(item.role_id === 1 || item.role_id === 5)
        ) || []
      state.userList = list
      state.filterUserList = list
    })
  }

  onMounted(() => {
    const userInfos = useUserStore().userInfo
    const params = {
      companyId: userInfos.company_id,
      departmentId: userInfos.department_id,
      teamId: userInfos.team_id,
      accountStatus: props.allUser ? undefined : 1
    }

    userList(params).then((res) => {
      const list = res.data.list?.filter((item: any) => !(item.role_id === 1 || item.role_id === 5)) || []
      state.userList = list
      state.filterUserList = list
    })
  })

  const tableSelectAll = () => {
    const nowWillSelectedkeys = state.filterUserList?.map((item: any) => item.id) || []

    const tempArr = [] as any[]
    nowWillSelectedkeys.forEach((item: any) => {
      if (!selectedUserKeys.value.includes(item)) {
        tempArr.push(item)
        state.selectedUser.push(state.filterUserList.find((user: any) => user.id === item))
      }
    })
    selectedUserKeys.value = selectedUserKeys.value.concat(tempArr)
  }

  const tagClose = (val: number) => {
    state.selectedUser = state.selectedUser.filter((item: any) => item.id !== val)
    selectedUserKeys.value = selectedUserKeys.value.filter((item: any) => item !== val)
  }

  const userFilter = () => {
    state.filterUserList = state.userList.filter(
      (item) =>
        item.username.includes(state.filterStr) &&
        (state.filterLevel.length === 0 || state.filterLevel.includes(item.user_level))
    )
  }
</script>

<template>
  <a-trigger trigger="click" show-arrow :popup-offset="5">
    <a-input-tag
      v-model:model-value="state.selectedUser"
      :format-tag="(tag) => tag.username"
      :style="{ width: props.width, minWidth: '150px' }"
      :max-tag-count="2"
      allow-clear
      @clear="reset"
    />
    <template #content>
      <div class="user-filter">
        <div class="gird">
          <div class="inputBox">
            <a-input
              v-model:model-value="state.filterStr"
              allow-clear
              :max-length="20"
              :show-word-limit="true"
              style="width: 50%"
              @change="userFilter"
            >
              <template #suffix> <icon-search /> </template>
            </a-input>
            <a-button @click="tableSelectAll">全选表格</a-button>
            <a-button @click="reset">清空</a-button>
            <div v-if="useUserStore().isAdmin || useUserStore().isYunYing" style="width: 100%; margin-top: 10px">
              <a-checkbox-group v-model="state.filterLevel" @change="userFilter">
                <a-checkbox :value="1">A</a-checkbox>
                <a-checkbox :value="2">B</a-checkbox>
                <a-checkbox :value="3">C</a-checkbox>
                <a-checkbox :value="4">D</a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
          <CompanyTree @tree-click="treeClick"></CompanyTree>
          <UserTable
            v-model:selected-user="state.selectedUser"
            v-model:selected-user-keys="selectedUserKeys"
            :user-list="state.filterUserList"
          ></UserTable>
        </div>
        <div class="tags">
          <a-scrollbar :style="{ overflow: 'auto' }">
            <div class="tagContainer">
              <span v-for="item in state.selectedUser" :key="item.id">
                <a-tag style="background-color: rgb(var(--primary-6)); color: #fff" closable @close="tagClose(item.id)">
                  {{ item.username }}
                </a-tag>
              </span>
            </div>
          </a-scrollbar>
        </div>
      </div>
    </template>
  </a-trigger>
</template>

<style lang="less" scoped>
  .user-filter {
    --box-height: 300px;
    display: grid;
    grid-template-columns: 440px 220px;
    min-width: fit-content;
    background-color: var(--color-bg-2);
    transition: all 0.3s;
    max-height: var(--box-height);

    .gird {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'inputBox inputBox'
        'tree table';
      max-height: var(--box-height);

      .inputBox {
        grid-area: inputBox;
        padding: 10px;
        border: 1px solid var(--color-border-1);
        border-bottom: 0;
        background-color: var(--color-bg-1);
        display: flex;
        flex-wrap: wrap;

        .arco-input-wrapper {
          margin-right: 8px;
        }
      }

      .arco-tree {
        border: 1px solid var(--color-border-1);
        border-right: 0;
      }

      .arco-table {
        border: 1px solid var(--color-border-1);
        border-right: 0;

        :deep(.arco-table-container) {
          border-left: 0;
          border-right: 0;
        }
      }
    }
  }

  .tags {
    border: 1px solid var(--color-border-1);
    // border-left: 0px;
    // padding: 10px;
    max-width: 250px;
    max-height: var(--box-height);

    .tagContainer {
      margin: 10px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      column-gap: 4px;
      max-height: calc(var(--box-height) - 20px);
    }
  }
  .arco-table,
  .arco-tree {
    height: calc(100%);
    overflow: auto;
  }

  .arco-tree::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
</style>
