import type { Router } from 'vue-router'
import { setRouteEmitter } from '@/utils/route-listener'
import setupUserLoginInfoGuard from './userLoginInfo'
import setupPermissionGuard from './permission'
import setupDictGuard from './dict'
import setBrowserGuard from './browser'

function setupPageGuard(router: Router) {
  router.beforeEach(async (to) => {
    // emit route change
    setRouteEmitter(to)
  })
}

export default function createRouteGuard(router: Router) {
  setBrowserGuard(router)
  setupUserLoginInfoGuard(router)
  setupPageGuard(router)
  setupPermissionGuard(router)
  setupDictGuard(router)
}
