<script lang="ts" setup>
  import { reactive, onMounted, ref, defineEmits } from 'vue'
  import { entCompanyList, entDeptList, entTeamList } from '@/api/organizational-structure'
  import { useUserStore } from '@/store'

  const state = reactive({
    checkedKeys: [],
    treeData: []
  })

  onMounted(() => {
    const userStore = useUserStore()
    const userInfoGet = userStore.userInfo
    const role = useUserStore().getRole
    if (userStore.isAdmin || userStore.isYunYing) {
      entCompanyList({}).then((res) => {
        state.treeData = res.data.CompanyList.map((item) => {
          return {
            ...item,
            children: [],
            title: item.name,
            key: item.id,
            type: 'company'
          }
        })
      })
    } else if (role === 'deptManager') {
      entDeptList({ Id: userInfoGet.department_id }).then((res) => {
        state.treeData = res.data.List.map((item) => {
          return {
            ...item,
            children: [],
            title: item.name,
            key: item.id,
            type: 'dept'
          }
        })
      })
    } else if (role === 'teamLeader') {
      entTeamList({ Id: userInfoGet.team_id }).then((res) => {
        state.treeData = res.data.List.map((item) => {
          return {
            ...item,
            children: [],
            title: item.name,
            key: item.id,
            type: 'team',
            isLeaf: true
          }
        })
      })
    }
  })
  const emit = defineEmits(['treeClick'])

  const loadMore = async (node: any) => {
    if (node.type === 'company') {
      entDeptList({ CompanyId: node.id }).then((res) => {
        const children = res.data.List?.map((item) => {
          return {
            ...item,
            children: [],
            title: item.name,
            key: item.id,
            type: 'dept'
          }
        })
        if (!children) {
          node.isLeaf = true
        }
        node.children = children
      })
    } else if (node.type === 'dept') {
      entTeamList({ departmentId: node.id }).then((res) => {
        const children = res.data.List?.map((item) => {
          return {
            ...item,
            children: [],
            title: item.name,
            key: item.id,
            type: 'team',
            isLeaf: true
          }
        })
        node.children = children
      })
    }
  }

  let clickTemp: any

  const treeRef = ref<any>(null)

  const treeClick = (s: any, data: any) => {
    // 取消节点跳过处理
    if (!s.length) {
      return
    }
    let temp: any
    if (clickTemp && clickTemp[0] === s[0]) {
      temp = { node: { type: undefined } }
      // 点击同一个节点取消选中
      treeRef.value.selectNode(s, false)
      clickTemp = undefined
    } else {
      temp = data
      clickTemp = s
    }
    emit('treeClick', temp)
  }
</script>

<template>
  <a-tree
    ref="treeRef"
    :data="state.treeData"
    :load-more="loadMore"
    :field-names="{ children: 'children', title: 'name', key: 'name' }"
    @select="treeClick"
  />
</template>

<style lang="less" scoped></style>
