import axios from 'axios'
import type { RouteRecordNormalized } from 'vue-router'
import { UserState } from '@/store/modules/user/types'

export interface LoginRes {
  token: string
}
export function login(data: any) {
  return axios.post<LoginRes>('/auth/login', data)
}

export function logout() {
  return axios.delete<LoginRes>('/auth/logout')
}

export function getUserInfo() {
  return axios.post<UserState>('/api/user/info')
}

export function getMenuList() {
  return axios.post<RouteRecordNormalized[]>('/api/user/menu')
}

export function loginBySms(data: any) {
  return axios.post<LoginRes>('auth/loginCaptcha', data)
}
