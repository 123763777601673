import axios from 'axios'

export function dictTree() {
  return axios.get('/api/dict/tree')
}
export function dictEdit(params: any) {
  return axios.patch('/api/dict/edit', params)
}
export function dictCreate(params: any) {
  return axios.post('/api/dict/create', params)
}
export function dictDel(params: any) {
  return axios.delete('/api/dict/delete', { params })
}
export function dictItemCreate(params: any) {
  return axios.post('/api/dict/item/create', params)
}
export function dictItemTree(params: any) {
  return axios.get('/api/dict/item/list', { params })
}
export function dictItemEdit(params: any) {
  return axios.patch('/api/dict/item/edit', params)
}
export function dictItemDel(params: any) {
  return axios.delete('/api/dict/item/delete', { params })
}
export function roleTree() {
  return axios.get('/api/rbac/role/list')
}
export function roleCreate(params: any) {
  return axios.post('/api/rbac/role/create', params)
}
export function roleDelete(params: any) {
  return axios.delete('/api/rbac/role/delete', { params })
}
export function roleEdit(params: any) {
  return axios.patch('/api/rbac/role/edit', params)
}
// 弃用
export function rbacPremTree() {
  return axios.get('/api/rbac/prem/tree')
}
export function rbacPremList(params: any) {
  return axios.get('/api/rbac/prem/list', { params })
}
export function rbacPremEdit(params: any) {
  return axios.patch('/api/rbac/prem/edit', params)
}
export function rbacPremCreate(params: any) {
  return axios.post('/api/rbac/prem/create', params)
}
export function rbacPremDelete(params: any) {
  return axios.delete('/api/rbac/prem/delete', { params })
}
// 弃用
export function rbacPremTreeById(params: any) {
  return axios.get('/api/rbac/rolePerm/tree', { params })
}
export function rbacPremBind(params: any) {
  return axios.post('/api/rbac/rolePerm/bind', params)
}
export function rbacPremUnbind(params: any) {
  return axios.post('/api/rbac/rolePerm/unbind', params)
}
export function settingList() {
  return axios.get('/api/setting/list')
}
export function settingCreate(params: any) {
  return axios.post('/api/setting/create', params)
}
export function settingEdit(params: any) {
  return axios.patch('/api/setting/edit', params)
}
export function settingDel(params: any) {
  return axios.delete('/api/setting/delete', { params })
}
export function sysLogSmsList(params: any) {
  return axios.get('/api/sysLog/sms/list', { params })
}

export function sysNoticeCreate(params: any) {
  return axios.get('/api/global_notice/create', { params })
}

export function sysNoticeList(params: any) {
  return axios.get('/api/global_notice/list', { params })
}
export function sysNoticeQuery(params: any) {
  return axios.get('/api/global_notice/query_pop', { params })
}

export function sysNoticeConfirm(params: any) {
  return axios.post('/api/global_notice/confirm', params)
}

export function sysSoupGetOne(params: any) {
  return axios.get('/api/soup/getOne', { params })
}

export function sysSoupList(params: any) {
  return axios.get('/api/soup/list', { params })
}

export function sysSoupDelete(params: any) {
  return axios.delete('/api/soup/delete', { params })
}

export function sysSoupAdd(params: any) {
  return axios.post('/api/soup/add', params)
}

export function sysSoupUpdate(params: any) {
  return axios.patch('/api/soup/update', params)
}
