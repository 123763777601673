import { App } from 'vue'
import { use } from 'echarts/core'
import UserFilter from './user-filter/index.vue'
import DictSelect from './dict-select/index.vue'
import ProductBrandFilter from './product-brand-filter/index.vue'
// import { CanvasRenderer } from 'echarts/renderers'
// import { BarChart, LineChart, PieChart, RadarChart } from 'echarts/charts'
// import {
//   GridComponent,
//   TooltipComponent,
//   LegendComponent,
//   DataZoomComponent,
//   GraphicComponent
// } from 'echarts/components'
// import Chart from './chart/index.vue'
// import Breadcrumb from './breadcrumb/index.vue'

// Manually introduce ECharts modules to reduce packing size

use([
  // CanvasRenderer,
  // BarChart,
  // LineChart,
  // PieChart,
  // RadarChart,
  // GridComponent,
  // TooltipComponent,
  // LegendComponent,
  // DataZoomComponent,
  // GraphicComponent
])

export default {
  install(Vue: App) {
    // Vue.component('Chart', Chart)
    // Vue.component('Breadcrumb', Breadcrumb)
    Vue.component('UserFilter', UserFilter)
    Vue.component('DictSelect', DictSelect)
    Vue.component('ProductBrandFilter', ProductBrandFilter)
  }
}
