<script lang="ts" setup>
  import { reactive, defineOptions, defineModel, defineProps, defineEmits } from 'vue'

  defineOptions({
    name: ''
  })
  const state = reactive({})
  const props = defineProps({
    userList: {
      type: Array,
      default: () => []
    }
  }) as any
  const selectedUserKeys = defineModel('selectedUserKeys', {
    required: false,
    type: Array
  }) as any
  const selectedUser = defineModel('selectedUser', {
    required: false,
    type: Array
  }) as any

  const emit = defineEmits(['selection-change'])
  const selectedUserChange = (rowKeys: any, rowKey: any, record: any) => {
    if (rowKeys.includes(rowKey)) {
      selectedUser.value.push(record)
    } else {
      selectedUser.value = selectedUser.value.filter((item: any) => item.id !== record.id)
    }
  }
</script>

<template>
  <a-table
    v-model:selected-keys="selectedUserKeys"
    row-key="id"
    :data="props.userList"
    :pagination="false"
    :show-header="false"
    :row-selection="{
      type: 'checkbox'
    }"
    :scroll="{ y: 300 }"
    size="small"
    @selection-change="emit('selection-change')"
    @select="selectedUserChange"
  >
    <template #columns>
      <a-table-column title="姓名" data-index="username"></a-table-column>
    </template>
  </a-table>
</template>

<style lang="less" scoped></style>
